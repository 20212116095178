import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux"

import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './state/store'
import App from './App';


import './index.css';
import "tw-elements-react/dist/css/tw-elements-react.min.css";

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </Router>
    </React.StrictMode>
);
