import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import courseReducer from './course/courseSlice';
import userReducer from './user/userSlice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['course', 'user'],
};

const rootReducer = combineReducers({
    course: courseReducer,
    user: userReducer,
});

// Transform the reducers with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                // Optionally, you can also ignore certain paths:
                // ignoredPaths: ['some.nested.path']
            }
        })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
